import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Skeleton, Checkbox, DatePicker, Space, Button } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';
import { isNullOrEmpty } from '~/helpers/util';

import { scheduleReadRequest, scheduleUpdateRequest, scheduleDeleteRequest } from '~/store/modules/schedule/actions';

export default function Update({ isVisible, setIsVisible, id, date }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.schedule.loading);
  const saving = useSelector(store => store.schedule.saving);
  const registry = useSelector(store => store.schedule.registry);
  const error = useSelector(store => store.schedule.error);

  const [isSaving, setIsSaving] = useState(false);
  const [lines, setLines] = useState([]);
  const [finished, setFinished] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    dispatch(scheduleUpdateRequest({ ...values, id: id, current: date }));
    setIsSaving(true);
  }

  function onDelete() {
    if (window.confirm('Deseja realmente excluir esta tarefa?')) {
      dispatch(scheduleDeleteRequest(id, date));
      setIsSaving(true);
    }
  }

  useEffect(() => {
    let grid = [];

    let hr = 0;
    let min = 0;

    for (let i = 0; i < 96; i++) {
      if (i % 4 === 0) {
        hr = i / 4;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:00`);
      }
      else {
        min = (i % 4) * 15;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:${min}`);
      }
    }
    setLines(grid);
  }, []);

  useMemo(() => {
    if (id) dispatch(scheduleReadRequest(id));
  }, [id]);

  useMemo(() => {
    if (!isNullOrEmpty(registry?.id)) {
      form.setFieldsValue({
        id: registry?.name, id,
        name: registry?.name,
        date: moment(registry?.startAt.split(' ')[0]),
        startAt: `${registry?.startAt.split(' ')[1].split(':')[0]}:${registry?.startAt.split(' ')[1].split(':')[1]}`,
        endAt: `${registry?.endAt.split(' ')[1].split(':')[0]}:${registry?.endAt.split(' ')[1].split(':')[1]}`,
        finished: registry?.finished === 1 ? true : false
      });

      setFinished(registry?.finished === 1 ? true : false);
    }
  }, [registry]);

  useMemo(() => {
    if (isSaving && !saving && isVisible) {
      setIsSaving(false);

      if (!error) {
        setIsVisible(false);
      }
    }
  }, [saving]);

  return (
    <Modal
      title="Editar tarefa"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
      processing={saving}
      footer={[
        <Space style={{ padding: 8 }}>
          <Button loading={saving} type="primary" onClick={() => { form.submit() }}>Salvar</Button>
          <Button loading={saving} type="primary" danger onClick={() => onDelete()}>Excluir</Button>
          <Button type="default" onClick={() => { setIsVisible(false) }}>Fechar</Button>
        </Space>
      ]}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Título"
              name="name"
              rules={[{ required: true, message: 'Título é obrigatório' }]}
            >
              {loading ? (
                <Skeleton.Button active size="small" block />
              ) : (
                <Input />
              )}
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Data"
              name="date"
              rules={[{ required: true, message: 'Data é obrigatória' }]}
            >
              {loading ? (
                <Skeleton.Button active size="small" block />
              ) : (
                <DatePicker format={"DD/MM/YYYY"} />
              )}
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Início"
              name="startAt"
              rules={[{ required: true, message: 'Início é obrigatório' }]}
            >
              {loading ? (
                <Skeleton.Button active size="small" block />
              ) : (
                <Select>
                  {lines.map(hr => (
                    <Select.Option key={hr}>{hr}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Fim"
              name="endAt"
              rules={[{ required: true, message: 'Término é obrigatório' }]}
            >
              {loading ? (
                <Skeleton.Button active size="small" block />
              ) : (
                <Select>
                  {lines.map(hr => (
                    <Select.Option key={hr}>{hr}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="finished"
            >
              {loading ? (
                <Skeleton.Button active size="small" block />
              ) : (
                <Checkbox checked={finished} onChange={e => { form.setFieldsValue({ finished: !finished }); setFinished(!finished) }}>Marcar como concluída</Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
