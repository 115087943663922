import styled from 'styled-components';

export const Container = styled.div`
  .header, .grid {
    display: flex;
    flex-direction: row;
    border: none;
    border-bottom: 1px solid #ddd;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
  }
  
  .today {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      margin-left: .7rem;
      font-weight: bold;
    }
  }

  .grid {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    overflow: auto;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    height: calc(100vh - 200px);
  }

  table {
    width: 100%;

    tr {
      .separator {
        border-top: 1px solid #ddd;
      }

      &:first-child {
        .separator {
          border-top: none;
        }
      }
    }

    td {
      max-width: 100%;

      &:first-child {
        width: 50px;
        border-right: 1px solid #ddd;
      }

      .content {
        display: block;
        width: 100%;
        height: 22px;
      }
    }
  }
`;

export const Task = styled.div`
  max-width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: .4rem;
  background-color: #fe0;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 10px;
  overflow: hidden;
  margin-right: 1rem;
  
  h4 {
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
  }

  svg {
    margin-right: 7px;
  }
  
  &.finished {
    background-color: #9f0;
  }
`;