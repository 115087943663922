import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import project from './project/sagas';
import schedule from './schedule/sagas';
import user from './user/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    project,
    schedule,
    user
  ]);
}
