import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { Form,  Row, Col, } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from '~/pages/_layouts/auth/styles';

import { Input } from '~/pages/_layouts/auth/styles';



import { Box } from './styles';

function Recovery() {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState('optional');

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  return (
    
    <Form
    
      form={form}
      layout="vertical"
      initialValues={{
        requiredMarkValue: requiredMark,
      }}
      onValuesChange={onRequiredTypeChange}
      requiredMark={requiredMark}
    >
      <Row>
        <Col xs={0} sm={3} md={6} lg={8}></Col>

        <Box xs={24} sm={18} md={12} lg={8}>
          <Form.Item>
            <h1 style={{textAlign:'center', fontSize:'50px' }}>Recuperação de senha</h1>
          </Form.Item>
          
          <Form.Item label= "Digite seu e-mail para redefinição de senha">
            <Input  placeholder="E-mail" />
          </Form.Item>
          <Form.Item>
            <Button
            shape='aroud'
            icon={<SearchOutlined />} 
            type="primary" 
             >
             Enviar
            </Button>  
          </Form.Item>

          <Link className="login-form-forgot" to="/signin" style={{color:'#000000' }} >
              <strong><u>Voltar para tela inicial</u></strong>
          </Link>
          
          
        </Box>
      </Row>
    </Form>
  );
}

export default Recovery;