import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import history from '~/services/history';

import { ProjectOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Container, Item } from './styles';

export default function Menu({ path }) {
  const profile = useSelector(store => store.auth.profile);
  const [selectedMenu, setSelectedMenu] = useState();

  const menuItens = [
    { key: 1, title: 'Agenda', breadcrumb: undefined, icon: <ScheduleOutlined />, url: '/schedule' },
    { key: 2, title: 'Projetos', breadcrumb: undefined, icon: <ProjectOutlined />, url: '/projects' },
  ];
  useEffect(() => {
    const menu = menuItens.find(a => a.url === path);
    if (menu) setSelectedMenu(menu.key);
  }, [path]);

  return (
    <Container
      theme="dark"
      defaultSelectedKeys={selectedMenu}
      mode="inline"
    >
      {menuItens.map(m => {
        if (m) {
          return (
            <Item key={m.key} icon={m.icon} onClick={() => history.push(m.url)}>
              {m.title}
            </Item>
          )
        }
      })}
    </Container>
  );
}
