import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/pt_BR';

import '~/config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';

import { store, persistor } from '~/store';

import GlobalStyle from './styles/global';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ConfigProvider locale={locale}>
            <Routes />
          </ConfigProvider>

          <ToastContainer autoClose={3000} />
        </Router>

        <GlobalStyle />
      </PersistGate>
    </Provider>
  );
}

export default App;
