export function userIndexRequest(filters) {
  return {
    type: '@users/INDEX_REQUEST',
    payload: { filters },
  };
}

export function userIndexSuccess(data) {
  return {
    type: '@users/INDEX_SUCCESS',
    payload: { data },
  };
}

export function userIndexFailure() {
  return {
    type: '@users/INDEX_FAILURE',
  };
}

export function userCreateRequest(data) {
  return {
    type: '@users/CREATE_REQUEST',
    payload: { data },
  };
}

export function userCreateSuccess(data) {
  return {
    type: '@users/CREATE_SUCCESS',
    payload: { data },
  };
}

export function userCreateFailure() {
  return {
    type: '@users/CREATE_FAILURE',
  };
}

export function userReadRequest(id) {
  return {
    type: '@users/READ_REQUEST',
    payload: { id },
  };
}

export function userReadSuccess(data) {
  return {
    type: '@users/READ_SUCCESS',
    payload: { data },
  };
}

export function userReadFailure() {
  return {
    type: '@users/READ_FAILURE',
  };
}

export function userUpdateRequest(data) {
  return {
    type: '@users/UPDATE_REQUEST',
    payload: { data },
  };
}

export function userUpdateSuccess(data) {
  return {
    type: '@users/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function userUpdateFailure() {
  return {
    type: '@users/UPDATE_FAILURE',
  };
}

export function userDeleteRequest(id) {
  return {
    type: '@users/DELETE_REQUEST',
    payload: { id },
  };
}

export function userDeleteSuccess() {
  return {
    type: '@users/DELETE_SUCCESS',
  };
}

export function userDeleteFailure() {
  return {
    type: '@users/DELETE_FAILURE',
  };
}
