import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input } from 'antd';

import Modal from '~/components/Modal';

import { projectCreateRequest } from '~/store/modules/project/actions';

export default function Create({ isVisible, setIsVisible }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.project.saving);
  const error = useSelector(store => store.project.error);

  const [isSaving, setIsSaving] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    dispatch(projectCreateRequest(values));
    setIsSaving(true);
  }

  function onReset() {
    form.setFieldsValue({ name: '' });
  }

  useMemo(() => {
    if (isSaving && !loading && isVisible) {
      setIsSaving(false);

      if (!error) {
        setIsVisible(false);
        onReset();
      }
    }
  }, [loading]);

  return (
    <Modal
      title="Novo projeto"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
      processing={loading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'Nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
