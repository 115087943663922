export const listYesNo = [{ key: 'true', value: "Sim" }, { key: 'false', value: "Não" }];

export const avatarColors = [
  '#eb2f96',
  '#f5222d',
  '#fadb14',
  '#fa8c16',
  '#13c2c2',
  '#52c41a',
  '#1890ff',
  '#722ed1',
  '#2f54eb',
  '#eb2f96',
  '#fa541c',
  '#faad14',
  '#a0d000'
];
