import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { signInRequest } from '~/store/modules/auth/actions';

import { LoginOutlined, MailOutlined, LockOutlined, UserAddOutlined } from '@ant-design/icons';
import { Card, AppName, Title, ImageContainer, Form, Input, Button, FormItem, Link } from '~/pages/_layouts/auth/styles';
import { Container } from './styles';

import image from '~/assets/images/login.jpg';

export default function SignIn() {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.auth.loading);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, password } = values;

    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      <Row style={{ height: '100%' }}>
        <Col xs={24} md={12} lg={11} xl={10} style={{ height: '100%', overflow: 'auto' }}>
          <Card>
            <AppName>
              <hr />
              <div>
                <span>StationUp</span>
                <span>DevOps</span>
              </div>
              <hr />
              <span>Powered by <a href="https://www.webstationdesign.com.br" target="_blank">WebStation</a></span>
            </AppName>

            <Title>Faça seu login</Title>

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row>
                <Col xs={24}>
                  <FormItem
                    name="email"
                    rules={[{ required: true, message: 'E-mail é obrigatório' }]}
                  >
                    <div className="input-group">
                      <MailOutlined />
                      <input placeholder="E-mail" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    name="password"
                    rules={[{ required: true, message: 'Senha é obrigatória' }]}
                  >
                    <div className="input-group">
                      <LockOutlined />
                      <input type="password" placeholder="Senha" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem>
                    <Button
                      shape='aroud'
                      icon={<LoginOutlined />}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Entrar
                    </Button>
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col xs={24} className="col-flex-center">
                  <Link to="/forgot">Esqueci minha senha</Link>
                </Col>
              </Row>

              <Row>
                <Col xs={24} className="col-flex-center" style={{ marginTop: 40, marginBottom: 30 }}>
                  <Link to="/register" className="primary-color">
                    <UserAddOutlined />
                    Criar conta
                  </Link>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col xs={0} md={12} lg={13} xl={14} style={{ height: '100%', overflow: 'hidden' }}>
          <ImageContainer>
            <img src={image} height="120%" />
          </ImageContainer>
        </Col>
      </Row>
    </Container>
  );
}
