import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import Error404 from '~/pages/_error404';
import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import Recovery from '~/pages/Recovery';

import Home from '~/pages/Schedule';
import Project from '~/pages/Home';
import ProjectDetails from '~/pages/Project/Details';
import Schedule from '~/pages/Schedule';

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={SignIn} />
      <Route path="/recovery" component={Recovery} />
      <Route path="/register" component={SignUp} />
      <Route path="/404" exact component={Error404} />

      <Route path="/" exact component={Home} isPrivate />
      <Route path="/projects" exact component={Project} isPrivate />
      <Route path="/projects/:uuid" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:uuid/backlog" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:uuid/features" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:uuid/epics" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:uuid/sprints" exact component={ProjectDetails} isPrivate />
      <Route path="/projects/:uuid/repositories" exact component={ProjectDetails} isPrivate />
      <Route path="/schedule" exact component={Schedule} isPrivate />

      <Redirect to="/404" />
    </Switch>
  );
}