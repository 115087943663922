import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import {
  scheduleIndexRequest, scheduleIndexSuccess, scheduleIndexFailure, scheduleCreateSuccess, scheduleCreateFailure,
  scheduleReadSuccess, scheduleReadFailure, scheduleUpdateSuccess, scheduleUpdateFailure, scheduleDeleteSuccess,
  scheduleDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    const { filters } = payload;

    let params = '';

    if (Array.isArray(filters)) {
      filters.map(e => {
        if (Object.keys(e).length === 1) {
          params += `${isNullOrEmpty(params) ? "?" : "&"}${Object.keys(e)[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });
    }

    const response = yield call(api.get, `tasks${params}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(scheduleIndexSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(scheduleIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { name, startAt, endAt, date, finished, current } = payload.data;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('startAt', `${moment(date).format('YYYY-MM-DD')} ${startAt}:00`);
    formData.append('endAt', `${moment(date).format('YYYY-MM-DD')} ${endAt}:00`);
    formData.append('finished', finished ? 1 : 0);

    const response = yield call(api.post, 'tasks', formData);


    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(scheduleCreateSuccess(data));
    yield put(scheduleIndexRequest([{ date: moment(current).format('YYYY-MM-DD') }]));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(scheduleCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `tasks/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(scheduleReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(scheduleReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { id, name, startAt, endAt, date, finished, current } = payload.data;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('startAt', `${moment(date).format('YYYY-MM-DD')} ${startAt}:00`);
    formData.append('endAt', `${moment(date).format('YYYY-MM-DD')} ${endAt}:00`);
    formData.append('finished', finished ? 1 : 0);

    const response = yield call(api.post, `tasks/${id}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(scheduleUpdateSuccess(data));
    yield put(scheduleIndexRequest([{ date: moment(current).format('YYYY-MM-DD') }]));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(scheduleUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id, date } = payload;

    const response = yield call(api.delete, `tasks/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(scheduleDeleteSuccess());
    yield put(scheduleIndexRequest([{ date: moment(date).format('YYYY-MM-DD') }]));

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(scheduleDeleteFailure());
  }
}

export default all([
  takeLatest('@schedules/INDEX_REQUEST', onIndex),
  takeLatest('@schedules/CREATE_REQUEST', onCreate),
  takeLatest('@schedules/READ_REQUEST', onRead),
  takeLatest('@schedules/UPDATE_REQUEST', onUpdate),
  takeLatest('@schedules/DELETE_REQUEST', onDelete),
]);