import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { signUpRequest } from '~/store/modules/auth/actions';

import { LoginOutlined, UserOutlined, BankOutlined, MailOutlined, LockOutlined, UserAddOutlined, CheckOutlined } from '@ant-design/icons';
import { Card, AppName, Title, ImageContainer, Form, Input, InputDate, Button, FormItem, Link } from '~/pages/_layouts/auth/styles';
import { Container } from './styles';

import image from '~/assets/images/register.jpg';

export default function SignUp() {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.auth.loading);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { organization, name, email, emailConfirm, password, passwordConfirm, birth } = values;

    dispatch(signUpRequest(organization, name, email, emailConfirm, password, passwordConfirm, birth));
  }

  return (
    <Container>
      <Row style={{ height: '100%' }}>
        <Col xs={0} md={12} lg={13} xl={14} style={{ height: '100%', overflow: 'hidden' }}>
          <ImageContainer>
            <img src={image} height="100%" />
          </ImageContainer>
        </Col>

        <Col xs={24} md={12} lg={11} xl={10} style={{ height: '100%', overflow: 'auto' }}>
          <Card>
            <AppName>
              <hr />
              <div>
                <span>StationUp</span>
                <span>DevOps</span>
              </div>
              <hr />
              <span>Powered by <a href="https://www.webstationdesign.com.br" target="_blank">WebStation</a></span>
            </AppName>

            <Title>Faça seu cadastro</Title>

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row>
                <Col xs={24}>
                  <FormItem
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório' }]}
                  >
                    <div className="input-group">
                      <UserOutlined />
                      <input placeholder="Seu nome" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    name="organization"
                    rules={[{ required: true, message: 'Organização é obrigatória' }]}
                  >
                    <div className="input-group">
                      <BankOutlined />
                      <input placeholder="Sua organização" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    name="email"
                    rules={[{ required: true, message: 'E-mail é obrigatório' }]}
                  >
                    <div className="input-group">
                      <MailOutlined />
                      <input placeholder="Seu e-mail" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    name="emailConfirm"
                    rules={[{ required: true, message: 'Confirmação é obrigatória' }, ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('email') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Confirmação inválida'));
                      },
                    })]}
                  >
                    <div className="input-group">
                      <MailOutlined />
                      <input placeholder="Confirme seu e-mail" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    name="password"
                    rules={[{ required: true, message: 'Senha é obrigatória' }]}
                  >
                    <div className="input-group">
                      <LockOutlined />
                      <input type="password" placeholder="Sua senha" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    name="passwordConfirm"
                    rules={[{ required: true, message: 'Confirmação é obrigatória' }, ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('As senhas não combinam'));
                      },
                    })]}
                  >
                    <div className="input-group">
                      <LockOutlined />
                      <input type="password" placeholder="Confirme sua senha" />
                    </div>
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem>
                    <Button
                      shape='aroud'
                      icon={<UserAddOutlined />}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Cadastrar
                    </Button>
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col xs={24} className="col-flex-center" style={{ marginTop: 40, marginBottom: 30 }}>
                  <Link to="/login" className="primary-color">
                    <LoginOutlined />
                    Já possuo uma conta
                  </Link>
                </Col>
              </Row>
            </Form>

          </Card>
        </Col>
      </Row>
    </Container>
  );
}
