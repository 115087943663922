import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Row } from 'antd';

import history from '~/services/history';
import { signFailure } from '~/store/modules/auth/actions';

import { UserOutlined, BankOutlined, PoweroffOutlined, MenuOutlined } from '@ant-design/icons';
import { Container, Content, Dropdown, Menu, UserInfo, UserPerfil, UserName, UserMenu } from './styles';

export default function Header() {
  const dispatch = useDispatch();

  const profile = useSelector(store => store.auth.profile);
  const shortName = profile?.name?.split(' ')[0].trim();

  return (
    <Container>
      <Row align="center" justify="end">
        <Dropdown
          placement="bottomRight"
          overlay={() => (
            <UserMenu>
              <div className='user-menu-item'>
                <Avatar size={90} icon={<UserOutlined />} />
                <p>{profile?.name}</p>
                <UserPerfil>{profile?.organizationName}</UserPerfil>
              </div>
              <div className='user-menu-item'>
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={() => { }}
                    icon={<UserOutlined />}
                  >Gerenciar minha conta</Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => { }}
                    icon={<BankOutlined />}
                  >Alterar minha organização</Menu.Item>
                  <Menu.Item
                    key="3"
                    onClick={() => { if (window.confirm('Deseja realmente sair?')) { dispatch(signFailure()); history.push('/'); } }}
                    icon={<PoweroffOutlined />}
                  >Sair</Menu.Item>
                </Menu>
              </div>
            </UserMenu>
          )}>
          <Content>
            <Avatar size="large" icon={<UserOutlined />} />
            <UserInfo xs={0} sm={24}>
              <UserName>{shortName}</UserName>
            </UserInfo>
          </Content>
        </Dropdown>
      </Row>
    </Container>
  );
}
