import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api, { apiLog } from '~/services/api';
import history from '~/services/history';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const formData = new FormData();

    formData.append('email', email);
    formData.append('password', password);

    const response = yield call(api.post, 'login', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const { token } = result.data;

    const user = {
      id: result.data.uuid,
      name: result.data.name,
      email: result.data.email,
      active: result.data.active,
      organizationId: result.data.Organization?.uuid,
      organizationName: result.data.Organization?.name
    };

    api.defaults.headers.Authorization = `Bearer ${token}`;
    apiLog.defaults.headers.Authorization = `Bearer ${token}`;

    /*    const logData = new FormData();
        logData.append('description', `${user.name} logado com sucesso`);
        yield call(apiLog.post, 'users/logins/create', logData);*/

    yield put(signInSuccess(token, user));

    history.push('/');
  }
  catch (err) {
    CatchErrors(err);
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { organization, name, email, emailConfirm, password, passwordConfirm, birth } = payload;

    const formData = new FormData();

    formData.append('organization', organization);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('emailConfirm', emailConfirm);
    formData.append('password', password);
    formData.append('passwordConfirm', passwordConfirm);
    if (!isNullOrEmpty(birth)) formData.append('birth', moment(birth).format('YYYY-MM-DD'));

    const response = yield call(api.post, 'register', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    toast.success(result.message);

    history.push('/login');
  }
  catch (err) {
    console.log({ err });
    CatchErrors(err);
  }
  finally {
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  if (!payload) return;

  const { token, profile } = payload.auth;

  if (!token || !profile) {
    yield put(signFailure());
    return;
  }

  api.defaults.headers.Authorization = `Bearer ${token}`;
  apiLog.defaults.headers.Authorization = `Bearer ${token}`;

  /*const logData = new FormData();
  logData.append('description', `Bem-vindo de volta ${profile.name}`);
  yield call(apiLog.post, 'users/logins/create', logData);*/

  yield put(signInSuccess(token, profile));
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
]);