import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';
import { CatchErrors } from '../common';

import {
  userIndexRequest, userIndexSuccess, userIndexFailure, userCreateSuccess, userCreateFailure, userReadSuccess,
  userReadFailure, userUpdateSuccess, userUpdateFailure, userDeleteSuccess, userDeleteFailure
} from './actions';

export function* onIndex({ payload }) {
  try {
    const response = yield call(api.get, 'users');

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userIndexSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(userIndexFailure());
  }
}

export function* onCreate({ payload }) {
  try {
    const { name, organizationId, email, password, passwordConfirm, birth, master } = payload.data;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('organizationId', organizationId);
    formData.append('email', email);
    formData.append('emailConfirm', email);
    formData.append('password', password);
    formData.append('passwordConfirm', passwordConfirm);
    if (!isNullOrEmpty(birth)) formData.append('birth', moment(birth).format('DD/MM/YYYY'));
    formData.append('master', master === 'true' ? 1 : 0);

    const response = yield call(api.post, 'users/create', formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userCreateSuccess(data));
    yield put(userIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(userCreateFailure());
  }
}

export function* onRead({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `users/read/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userReadSuccess(data));
  }
  catch (err) {
    CatchErrors(err);
    yield put(userReadFailure());
  }
}

export function* onUpdate({ payload }) {
  try {
    const { id, name, organizationId, email, password, passwordConfirm, birth, active, master } = payload.data;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('organizationId', organizationId);
    formData.append('email', email);
    formData.append('emailConfirm', email);
    if (!isNullOrEmpty(password)) formData.append('password', password);
    if (!isNullOrEmpty(password)) formData.append('passwordConfirm', passwordConfirm);
    if (!isNullOrEmpty(birth)) formData.append('birth', moment(birth).format('DD/MM/YYYY'));
    formData.append('active', active === 'true' ? 1 : 0);
    formData.append('master', master === 'true' ? 1 : 0);

    const response = yield call(api.post, `users/update/${id}`, formData);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;
    if (!result.data) throw 'Verifique sua conexão';

    const data = result.data;

    yield put(userUpdateSuccess(data));
    yield put(userIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(userUpdateFailure());
  }
}

export function* onDelete({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.delete, `users/delete/${id}`);

    if (!response) throw 'Verifique sua conexão';

    const result = response.data;

    if (!result) throw 'Verifique sua conexão';
    if (!result.success) throw result.error;

    const data = result.data;

    yield put(userDeleteSuccess());
    yield put(userIndexRequest());

    toast.success(result.message);
  }
  catch (err) {
    CatchErrors(err);
    yield put(userDeleteFailure());
  }
}

export default all([
  takeLatest('@users/INDEX_REQUEST', onIndex),
  takeLatest('@users/CREATE_REQUEST', onCreate),
  takeLatest('@users/READ_REQUEST', onRead),
  takeLatest('@users/UPDATE_REQUEST', onUpdate),
  takeLatest('@users/DELETE_REQUEST', onDelete),
]);