import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';

import ModalCreate from './Create';
import ModalUpdate from './Update';

import { scheduleIndexRequest } from '~/store/modules/schedule/actions';

import { ArrowLeftOutlined, ArrowRightOutlined, SearchOutlined, SettingFilled, CheckCircleFilled, HourglassOutlined } from '@ant-design/icons';
import { Container, Task } from './styles';

export default function Schedule() {
  const dispatch = useDispatch();

  const dataSource = useSelector(store => store.schedule.list);
  const loading = useSelector(store => store.schedule.loading);

  const [lines, setLines] = useState([]);
  const [day, setDay] = useState();
  const [date, setDate] = useState();
  const [hour, setHour] = useState();
  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [isVisibleUpdate, setIsVisibleUpdate] = useState(false);
  const [id, setId] = useState();

  function createTask(hr) {
    setHour(hr);
    setIsVisibleCreate(true);
  }

  function updateTask(id) {
    setId(id);
    setIsVisibleUpdate(true);
    setTimeout(() => {
      setIsVisibleCreate(false);
    }, 150);
  }

  useEffect(() => {
    let grid = [];

    let hr = 0;
    let min = 0;

    for (let i = 0; i < 96; i++) {
      if (i % 4 === 0) {
        hr = i / 4;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:00`);
      }
      else {
        min = (i % 4) * 15;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:${min}`);
      }
    }
    setLines(grid);

    setDate(moment().format('YYYY-MM-DD'));
    setDay(moment().format("dddd, DD [de] MMMM [de] YYYY"));
  }, []);

  useEffect(() => {
    dispatch(scheduleIndexRequest([{ date: moment(date).format('YYYY-MM-DD') }]));
    setDay(moment(date).format("dddd, DD [de] MMMM [de] YYYY"));
  }, [date]);

  return (
    <Container>
      <Row>
        <Col xs={24} className='header'>
          <Button type='ghost' htmlType='button' onClick={() => setDate(moment().format('YYYY-MM-DD'))}>Hoje</Button>

          <div className='today'>
            <Button type='text' htmlType='button' icon={<ArrowLeftOutlined />} onClick={() => setDate(moment(date).add(-1, "d").format('YYYY-MM-DD'))}></Button>
            <Button type='text' htmlType='button' icon={<ArrowRightOutlined />} onClick={() => setDate(moment(date).add(1, "d").format('YYYY-MM-DD'))}></Button>
            <span>{day}</span>
          </div>

          <div>
            <Button type='text' htmlType='button' icon={<SearchOutlined />}></Button>
            <Button type='text' htmlType='button' icon={<SettingFilled />}></Button>
          </div>
        </Col>

        <Col xs={24} className='grid'>
          <table>
            <tbody>
              {lines.map((hr, i) => (
                <tr>
                  <td className={hr.split(':')[1] === '00' && 'separator'}>
                    {hr.split(':')[1] === '00' ? hr : ' '}
                  </td>
                  <td style={{ cursor: 'pointer' }}
                    className={hr.split(':')[1] === '00' && 'separator'}
                    onClick={() => createTask(hr)}
                    id={`schedule_${i}`}
                  >
                    {dataSource.filter(a => a.startAt === `${moment(date).format('YYYY-MM-DD')} ${hr}:00`).map(a => {
                      let points = 1;

                      if (a) {
                        const end = lines.findIndex(b => b === `${a.endAt.split(' ')[1].split(':')[0]}:${a.endAt.split(' ')[1].split(':')[1]}`);
                        const start = lines.findIndex(b => b === `${a.startAt.split(' ')[1].split(':')[0]}:${a.startAt.split(' ')[1].split(':')[1]}`);

                        points = end - start;
                      }

                      if (points <= 1) points = 1;

                      return (
                        <Task
                          style={{ height: points * 22 }}
                          onClick={() => updateTask(a.id)}
                          className={(a.finished === '1' || a.finished === 1) && `finished`}
                        >
                          <h4>
                            {(a.finished === '1' || a.finished === 1) ? <CheckCircleFilled /> : <HourglassOutlined />}
                            {a.name}
                          </h4>
                          <span>
                            {`${a.startAt.split(' ')[1].split(':')[0]}:${a.startAt.split(' ')[1].split(':')[1]} - ${a.endAt.split(' ')[1].split(':')[0]}:${a.endAt.split(' ')[1].split(':')[1]}`}
                          </span>
                        </Task>
                      )
                    })}
                    <span className='content'></span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>

      <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} hour={hour} date={date} />
      <ModalUpdate isVisible={isVisibleUpdate} setIsVisible={setIsVisibleUpdate} id={id} date={date} />
    </Container>
  );
}
