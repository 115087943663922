import styled from 'styled-components';
import { Form as _Form, Input as _input, Button as _Button, DatePicker } from 'antd';
import { Link as _Link } from 'react-router-dom';

export const Container = styled.div`
  user-select: none;
  background-color: var(--background);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export const Content = styled.div`
  margin: 0 auto;
  background-color: var(--background);
  width: 100%;
  max-width: 1920px;
  height: 100%;
  padding: 0;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;

  h1 {
    color: var(--primary-text);
    font-size: 35px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 300;

    .title-bold {
      font-weight: 700;
    }
  }

  h2 {
    color: var(--primary-text);
    font-size: 21px;
  }

  p {
    text-align: center;
  }

  .col-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AppName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
  
  span {
    font-size: 10px;
    color: var(--third-text);
    margin-top: 7px;
    margin-bottom: 7px;
  }

  hr {
    width: 100%;
    border-top: 1px solid var(--border-primary);
    height: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Kaushan Script', cursive;
    color: var(--secondary-text);
    font-size: 35px;
    padding: 3px 30px 3px 30px;

    span {
      font-family: 'Kaushan Script', cursive;
      color: var(--secondary-text);
      font-size: 35px;
      margin-top: 0;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  a {
    color: var(--third-text) !important;
  }
`;

export const Title = styled.h2`
  margin-bottom: 18px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Form = styled(_Form)`
  width: 100%;
  max-width: 320px;
`;

export const FormItem = styled(_Form.Item)`
  margin-bottom: 10px;

  .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    input {
      padding-left: 42px;
      z-index: 333;
    }

    svg {
      cursor: text;
      color: var(--input-text);
      position: relative;
      font-size: 16px;
      margin-right: -16px;
      left: 16px;
      z-index: 444;
    }
  }

  input {
    background: #251f2b;
    color: var(--input-text);
    background-color: var(--input-background) !important;
    border: 1px solid var(--input-border);
    border-radius: 10px;
    width: 100%;
    font-size: 12px;
    height: 44px;
    padding: 10px 20px;
    margin: 0;

    &::placeholder {
      color: var(--input-text-placeholder);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: all 5000s ease-in-out 0s;
    }
  }
`;

export const Input = styled(_input)`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
`;

export const InputDate = styled(DatePicker)`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #fff;
  background-color: #251f2b;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
`;

export const Button = styled(_Button)`
  width: 100%;
  border-color: var(--button-primary-border);
  background-color: var(--button-primary) !important;
  color: var(--button-primary-text);
  height: 44px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 10px;

  &:hover, &:focus, &:active {
    border-color: var(--button-primary-border-hover);
    background-color: var(--button-primary-hover) !important;
    color: var(--button-primary-text-hover);
  }
`;

export const Link = styled(_Link)`
  color: var(--anchor-primary);

  &.primary-color {
    color: var(--button-primary-border);

    &:hover {
    color: var(--button-primary-border);
    }
  }

  &:hover {
    color: var(--primary-text);
  }

  svg {
    margin-right: 10px;
  }
`;
