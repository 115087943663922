import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Checkbox, DatePicker } from 'antd';
import moment from 'moment';

import Modal from '~/components/Modal';

import { scheduleCreateRequest } from '~/store/modules/schedule/actions';

export default function Create({ isVisible, setIsVisible, hour, date }) {
  const dispatch = useDispatch();

  const loading = useSelector(store => store.schedule.saving);
  const error = useSelector(store => store.schedule.error);

  const [isSaving, setIsSaving] = useState(false);
  const [lines, setLines] = useState([]);
  const [finished, setFinished] = useState(false);

  const [form] = Form.useForm();

  function onFinish(values) {
    dispatch(scheduleCreateRequest({ ...values, current: date }));
    setIsSaving(true);
  }

  function onReset() {
    setFinished(false);

    form.setFieldsValue({
      name: '',
      date: '',
      startAt: '',
      endAt: '',
      finished: false
    });
  }

  useEffect(() => {
    let grid = [];

    let hr = 0;
    let min = 0;

    for (let i = 0; i < 96; i++) {
      if (i % 4 === 0) {
        hr = i / 4;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:00`);
      }
      else {
        min = (i % 4) * 15;
        grid.push(`${hr < 10 ? '0' : ''}${hr}:${min}`);
      }
    }
    setLines(grid);
  }, []);

  useMemo(() => {
    if (isSaving && !loading && isVisible) {
      setIsSaving(false);

      if (!error) {
        onReset();
        setIsVisible(false);
      }
    }
  }, [loading]);

  useMemo(() => {
    setFinished(false);

    form.setFieldsValue({
      name: '',
      date: moment(date),
      startAt: hour,
      endAt: hour,
      finished: false
    });
  }, [hour]);

  return (
    <Modal
      title="Nova tarefa"
      visible={isVisible}
      setVisible={setIsVisible}
      onOk={() => { form.submit() }}
      maskClosable={false}
      width={400}
      processing={loading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              label="Título"
              name="name"
              rules={[{ required: true, message: 'Título é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Data"
              name="date"
              rules={[{ required: true, message: 'Data é obrigatória' }]}
            >
              <DatePicker format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Início"
              name="startAt"
              rules={[{ required: true, message: 'Início é obrigatório' }]}
            >
              <Select>
                {lines.map(hr => (
                  <Select.Option key={hr}>{hr}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Fim"
              name="endAt"
              rules={[{ required: true, message: 'Término é obrigatório' }]}
            >
              <Select>
                {lines.map(hr => (
                  <Select.Option key={hr}>{hr}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="finished"
            >
              <Checkbox defaultChecked={finished} checked={finished} onChange={e => { form.setFieldsValue({ finished: !finished }); setFinished(!finished) }}>Marcar como concluída</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}
