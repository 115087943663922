import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  list: [],
  registry: {},
  loading: false,
  error: false
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@users/INDEX_REQUEST':
    case '@users/CREATE_REQUEST':
    case '@users/UPDATE_REQUEST':
    case '@users/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@users/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
        draft.registry = {};
      });

    case '@users/CREATE_SUCCESS':
    case '@users/UPDATE_SUCCESS':
    case '@users/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@users/INDEX_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.data;
      });

    case '@users/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = false;
        draft.registry = action.payload.data;
      });

    case '@users/INDEX_FAILURE':
    case '@users/CREATE_FAILURE':
    case '@users/UPDATE_FAILURE':
    case '@users/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case '@users/READ_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
        draft.registry = {};
      });

    default:
      return state;
  }
}
