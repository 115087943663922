export function projectIndexFirstRequest() {
  return {
    type: '@projects/INDEX_REQUEST',
    payload: { filters: [{ filterDeleted: 0 }] },
  };
}

export function projectIndexRequest(filters) {
  return {
    type: '@projects/INDEX_REQUEST',
    payload: { filters },
  };
}

export function projectIndexSuccess(data) {
  return {
    type: '@projects/INDEX_SUCCESS',
    payload: { data },
  };
}

export function projectIndexFailure() {
  return {
    type: '@projects/INDEX_FAILURE',
  };
}

export function projectCreateRequest(data) {
  return {
    type: '@projects/CREATE_REQUEST',
    payload: { data },
  };
}

export function projectCreateSuccess(data) {
  return {
    type: '@projects/CREATE_SUCCESS',
    payload: { data },
  };
}

export function projectCreateFailure() {
  return {
    type: '@projects/CREATE_FAILURE',
  };
}

export function projectReadRequest(id) {
  return {
    type: '@projects/READ_REQUEST',
    payload: { id },
  };
}

export function projectReadSuccess(data) {
  return {
    type: '@projects/READ_SUCCESS',
    payload: { data },
  };
}

export function projectReadFailure() {
  return {
    type: '@projects/READ_FAILURE',
  };
}

export function projectUpdateRequest(data) {
  return {
    type: '@projects/UPDATE_REQUEST',
    payload: { data },
  };
}

export function projectUpdateSuccess(data) {
  return {
    type: '@projects/UPDATE_SUCCESS',
    payload: { data },
  };
}

export function projectUpdateFailure() {
  return {
    type: '@projects/UPDATE_FAILURE',
  };
}

export function projectDeleteRequest(id) {
  return {
    type: '@projects/DELETE_REQUEST',
    payload: { id },
  };
}

export function projectDeleteSuccess() {
  return {
    type: '@projects/DELETE_SUCCESS',
  };
}

export function projectDeleteFailure() {
  return {
    type: '@projects/DELETE_FAILURE',
  };
}
