import styled from 'styled-components';
import { Menu } from 'antd';

export const Container = styled(Menu)`
  background: var(--header-background) !important;
`;

export const Item = styled(Menu.Item)`
  
`;

export const SubMenu = styled(Menu.SubMenu)`
  
`;
