import styled from 'styled-components';
import { Col,  } from 'antd';

export const Container = styled.div`
  width: 100%;
  max-width: 350px;
  min-height: 100px;
  margin: 0 auto;
  margin-top: 100px;
  background-color: #0000CD;
  opacity: 0.7;
`;

export const Box = styled(Col)`
  background-color: #ccc;
  padding: 30px;
  border-radius: 15px;
`;


