import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import '~/assets/css/buttons.scss';
import '~/assets/css/antd.scss';
import '~/assets/css/tabs.scss';
import '~/assets/css/common.scss';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    &:focus{
      outline: 0;
    }
  }

  html, body, #root {
    user-select: none;
    height: 100%;
  }

  body{
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .clickable {
    cursor: pointer;
  }

  .container-fluid {
    padding: 20px 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .text-danger {
    color: #dc3545 !important;
  }

  .text-success {
    color: #28a745 !important;
  }
`;
