export const isCPF = (value) => {
  const cpf = stringClear(value, "1234567890");

  // Verifica se foi informado todos os digitos corretamente
  if (isNullOrEmpty(cpf) || cpf.length !== 11) return false;

  // Verifica se foi informada uma sequência de digitos repetidos.
  switch (cpf) {
    case '00000000000':
    case '11111111111':
    case '22222222222':
    case '33333333333':
    case '44444444444':
    case '55555555555':
    case '66666666666':
    case '77777777777':
    case '88888888888':
    case '99999999999':
      return false;
  }

  // Caso coloque todos os numeros iguais
  const multiplicador1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];
  const multiplicador2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

  var tempCpf = cpf.substring(0, 9);
  var soma = 0;

  for (var i = 0; i < 9; i++) soma += Number(tempCpf.substring(i, i + 1)) * multiplicador1[i];

  var resto = soma % 11;

  if (resto < 2) resto = 0;
  else resto = 11 - resto;

  var digito = `${resto}`;

  tempCpf = tempCpf + digito;
  soma = 0;

  for (var i = 0; i < 10; i++) soma += Number(tempCpf.substring(i, i + 1)) * multiplicador2[i];

  resto = soma % 11;

  if (resto < 2) resto = 0;
  else resto = 11 - resto;

  digito = `${digito}${resto}`;

  return cpf.substring(9, 11) === digito;
}

export const isNullOrEmpty = (value) => {
  if (!value) return true;

  if (value === undefined) return true;

  if (value === 'undefined') return true;

  if (value === '') return true;

  if (value.length <= 0) return true;

  return false;
}

export const stringClear = (value, valid) => {
  var result = '';

  if (isNullOrEmpty(value)) return result;

  let aux = 0;
  for (var i = 0; i < `${value}`.length; i++) {
    aux = valid.indexOf(`${value}`.substring(i, i + 1));

    if (aux >= 0) result += `${value}`.substring(i, i + 1);
  }
  return result;
}

export const stringReplace = (value, charOrigin, charChange) => {

  var result = '';
  var isChange = false;

  if (isNullOrEmpty(value)) return result;


  if (charOrigin.length !== charChange.length || isNullOrEmpty(charOrigin) || isNullOrEmpty(charChange)) return value;

  for (let i = 0; i < value.length; i++) {
    isChange = false;

    for (let j = 0; j < charOrigin.length; j++) {
      if (value.substring(i, i + 1) === charOrigin.substring(j, j + 1)) {
        isChange = true;

        result += charChange.substring(j, j + 1);

        j = charOrigin.length;
      }
    }

    if (!isChange) result += value.substring(i, i + 1);
  }

  return result;
}

export const getBase64 = (file) => {
  let base64Str = "";

  try {
    const reader = new FileReader();

    reader.onloadend = () => {
      base64Str = String(reader.result);
    };

    reader.readAsDataURL(file);
  }
  catch (err) {
    //
  }
  finally {
    return base64Str;
  }
}

export function onlyNumber(evt) {
  return ["e", "E", "+"].includes(evt.key) && evt.preventDefault();
}

export function money(evt, naturals = false) {
  let blacklist = ["e", "E", "+", "."];
  if (naturals) blacklist.push('-');
  return blacklist.includes(evt.key) && evt.preventDefault();
}
