import React from 'react';
import { Tabs } from 'antd';

import Project from '../Project';

import { Container } from './styles';

export default function Home() {
  return (
    <Container>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Projetos" key="1">
          <Project />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Minhas tarefas" key="2">

        </Tabs.TabPane>
        <Tabs.TabPane tab="Minhas entregas" key="3">

        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
}
