import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Row, Tooltip, Spin } from 'antd';

import CardHeader from '~/components/CardHeader';
import ModalCreate from './Create';

import history from '~/services/history';

import { avatarColors } from '~/helpers/lists';

import { projectIndexFirstRequest, projectIndexRequest } from '~/store/modules/project/actions';

import { PlusOutlined, ScheduleOutlined, PullRequestOutlined, CrownOutlined, HistoryOutlined, HddOutlined, ProjectOutlined } from '@ant-design/icons';
import { Container, Empty, FilterContainer, FilterInput, FilterButton, ProjectItem, ProjectItemContainer, ProjectItemTitleContainer, ProjectItemTitle, ProjectItemDetailsContainer, ProjectItemIconsContainer } from './styles';

export default function Project() {
  const dispatch = useDispatch();

  const dataSource = useSelector(store => store.project.list);
  const loading = useSelector(store => store.project.loading);

  const [isVisibleCreate, setIsVisibleCreate] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');

  function onFilter(value) {
    setFilterSearch(value);
    dispatch(projectIndexRequest([{ filterSearch: value }]));
  }

  useEffect(() => {
    dispatch(projectIndexFirstRequest());
  }, []);

  return (
    <Container>
      <CardHeader>
        <FilterContainer>
          <Tooltip title="Pesquisar um projeto">
            <FilterInput placeholder='Digite um termo para busca' value={filterSearch} onChange={e => onFilter(e.target.value)} />
          </Tooltip>
        </FilterContainer>

        <Tooltip title="Criar um novo projeto">
          <FilterButton onClick={() => { setIsVisibleCreate(true); setFilterSearch(''); onFilter(''); }} disabled={loading}>
            <PlusOutlined />
            Novo projeto
          </FilterButton>
        </Tooltip>
      </CardHeader>

      {loading ? (
        <Container style={{ marginTop: '5%', justifyContent: 'center' }}>
          <Spin />
        </Container>
      ) : (
        <>
          {!dataSource || dataSource.length <= 0 ? (
            <Empty>
              <h2 style={{ textAlign: 'center', width: '100%' }}>Nenhum projeto encontrado.</h2>
            </Empty>
          ) : (
            <Row gutter={24}>
              {dataSource.map(item => {
                var i = 0;

                var words = item.name.split(' ');
                if (words.length <= 1) words = item.name.split('_');
                if (words.length <= 1) words = item.name.split('/');
                if (words.length <= 1) words = item.name.split('|');
                if (words.length <= 1) words = item.name.split('-');

                const initials = words.map(w => {
                  if (i >= 2) return '';
                  if (w.trim().length <= 0) return '';

                  const letter = w.trim().substring(0, 1);

                  if (!letter.match(/[a-z]/i)) return '';

                  i++;

                  return letter.toUpperCase();
                });

                const avatarColor = avatarColors[item.name.trim().length % avatarColors.length];

                return (
                  <ProjectItem xs={24} lg={12} xl={8}>
                    <ProjectItemContainer>
                      <ProjectItemTitleContainer onClick={() => history.push(`/projects/${item.uuid}`)}>
                        <Avatar data-color={item.name.trim().length % avatarColors.length} style={{ backgroundColor: avatarColor, verticalAlign: 'middle' }} size="large">
                          {initials}
                        </Avatar>
                        <ProjectItemTitle>{item.name}</ProjectItemTitle>
                      </ProjectItemTitleContainer>

                      <ProjectItemIconsContainer>
                        <Tooltip title="Dashboard">
                          <ProjectOutlined className='icon-project' onClick={() => history.push(`/projects/${item.uuid}`)} />
                        </Tooltip>

                        <Tooltip title="Elementos">
                          <ScheduleOutlined className='icon-backlog' onClick={() => history.push(`/projects/${item.uuid}/backlog`)} />
                        </Tooltip>

                        <Tooltip title="Linha do tempo">
                          <HistoryOutlined className='icon-sprint' onClick={() => history.push(`/projects/${item.uuid}/sprints`)} />
                        </Tooltip>

                        <Tooltip title="Recursos">
                          <HddOutlined className='icon-feature' onClick={() => history.push(`/projects/${item.uuid}/features`)} />
                        </Tooltip>

                        <Tooltip title="Épicos">
                          <CrownOutlined className='icon-epic' onClick={() => history.push(`/projects/${item.uuid}/epics`)} />
                        </Tooltip>

                        <Tooltip title="Repositório">
                          <PullRequestOutlined className='icon-repository' onClick={() => history.push(`/projects/${item.uuid}/repositories`)} />
                        </Tooltip>

                      </ProjectItemIconsContainer>
                    </ProjectItemContainer>
                  </ProjectItem>
                );
              })}
            </Row>
          )}
        </>
      )}
      <ModalCreate isVisible={isVisibleCreate} setIsVisible={setIsVisibleCreate} />
    </Container>
  );
}
