import { combineReducers } from 'redux';

import auth from './auth/reducer';
import project from './project/reducer';
import schedule from './schedule/reducer';
import user from './user/reducer';

export default combineReducers({
  auth,
  project,
  schedule,
  user
});