import React from 'react';

// import { Container } from './styles';

export default function Details(props) {
  console.log({ props });

  return (
    <h1></h1>
  );
}
