import styled from 'styled-components';
import {
  Layout as _Layout,
  Breadcrumb as _Breadcrumb,
  Form as _Form,
  Input as _Input,
  Button as _Button
} from 'antd';

export const Container = styled(_Layout)`
  min-height: 100vh;

  .logo {
    height: 32px;
    margin: 16px;

    img {
      height: 28px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 15px 30px;

  @media only screen and (max-width: 992px) {
    padding: 15px 20px;
  }
`;

export const Layout = styled(_Layout)`
  background: #fff;

  .site-layout-background {
    &.isMobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const Header = styled(_Layout.Header)`
  padding: 0;
  height: 70px;
  background: var(--header-background);
  
  @media only screen and (max-width: 992px) {
    height: 68px;
  }
`;

export const Footer = styled(_Layout.Footer)`
  margin: 0 16px;
  text-align: center;
`;

export const Body = styled(_Layout.Content)`
  margin: 0;
`;

export const Breadcrumb = styled(_Breadcrumb)`
  margin: 16px 0;
`;

export const Content = styled.div`
  min-height: 360px;
`;

export const Sider = styled(_Layout.Sider)`
  height: auto;
  background: var(--header-background);

  .logo {
    height: 50px;
  }

  .ant-layout-sider-trigger {
    display: ${props => props.className === 'isMobile' ? 'none' : 'block'};
    background: var(--header-background-secondary);
  }

  .ant-menu-inline-collapsed::before {
    display: unset;
  }  
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s;

  @media only screen and (max-width: 992px) {
    display: none;
  }

  span {
    font-size: 7px;
    line-height: 10px;
    color: var(--third-text);
    margin-top: 3px;
    margin-bottom: 3px;
  }

  hr {
    width: 100%;
    border-top: 1px solid var(--border-primary);
    height: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;

    span {
      font-family: 'Kaushan Script', cursive;
      color: var(--secondary-text);
      font-size: 20px;
      line-height: 32px;
      margin-top: 0;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  a {
    color: var(--third-text) !important;
  }

  &.is-collapsed {
    div {
      span {
        font-size: 16px;

        &:first-child {
          display: none;
        }
      }
    }
  }
`;

export const Form = styled(_Form)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const Input = styled(_Input)`
  background: rgba(255, 255, 255, 0.8);
  border: 0;
  border-radius: 4px;
  width: 100%;
  height: 34px;
  padding: 0 10px;
  color: #222;
  margin: 0;
  font-size: 13px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const Button = styled(_Button)`
  border-color: var(--button-primary-border);
  background-color: var(--button-primary) !important;
  color: var(--button-primary-text);
  height: 34px;
  border-radius: 10px;
  padding: 5px 15px;
  transition: background 0.2s;

  &:hover, &:focus, &:active {
    border-color: var(--button-primary-border-hover);
    background-color: var(--button-primary-hover) !important;
    color: var(--button-primary-text-hover);
  }

  &.fit-content {
    width: 100%;
  }
`;

export const MenuButton = styled(_Button)`
  margin: 5px 0 0;
  font-weight: bold;
  background: #fff;
  color: rgba(0, 0, 0, 0.85);
  border: 0;
  font-size: 16px;
  transition: background 0.2s;
`;